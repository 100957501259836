import {
  Environment,
  OrbitControls,
  useAnimations,
  useFBX,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";

import * as THREE from "three";
import { useState, useEffect, useRef, MutableRefObject } from "react";
import { useFrame } from "@react-three/fiber";

import {
  GetAllPowerUpgradesAPI,
  PowerUpgradeType,
} from "../../../_newapios/gamecontent/power_upgrades.ts";
import {
  GetStudentPowerAllAPI,
  StudentPowerType,
} from "../../../_newapios/progress/student_power.ts";
import {
  GetAllMyEutonsAPI,
  StudentEutonType,
} from "../../../_newapios/progress/student_euton.ts";
import { EutonType } from "../../../_newapios/gamecontent/eutons.ts";
import T2R3F from "./LocalComponents/T2R3F.tsx";
import T1R3F from "./LocalComponents/T1R3F.tsx";

const LocalMap = ({ ...props }) => {
  const controlsRef = useRef<any>(null);

  const studentState = useAppSelector((state) => state.student);
  const loginState = useAppSelector((state) => state.login);
  const eutons = useAppSelector((state) => state.main).eutons;

  const [funkyMode, setFunkyMode] = useState(false);

  const [powerUpgrades, setPowerUpgrades] = useState<PowerUpgradeType[]>([]);
  const [studentPower, setStudentPower] = useState<StudentPowerType[]>([]);

  const [studentEutons, setStudentEutons] = useState<StudentEutonType[]>([]);
  const [walkingEutons, setWalkingEutons] = useState<EutonType[]>([]);

  const theme = studentState.theme;

  useEffect(() => {
    if (studentState.theme?.id === "theme_id") {
      loadT1Progress();
    } else {
      loadT2Progress();
    }
  }, []);

  useEffect(() => {
    getEutons();
  }, [studentEutons]);

  const loadT1Progress = async () => {
    console.log("Loading T1 Progress");
    const temp_power_upgrades = await GetAllPowerUpgradesAPI();
    setPowerUpgrades(temp_power_upgrades);
    const temp_student_power = await GetStudentPowerAllAPI(
      props.studentId == null ? loginState.id : props.studentId
    );
    setStudentPower(temp_student_power);
  };
  const loadT2Progress = async () => {
    console.log("Loading T2 Progress");
    const temp_student_eutons = await GetAllMyEutonsAPI();
    const filtered_student_eutons = temp_student_eutons.filter(
      (se) => se.euton_unlocked
    );
    setStudentEutons(filtered_student_eutons);
  };

  const [ostrichEgg, setOstrichEgg] = useState(false);
  const [ostrichEggCount, setOstrichEggCount] = useState(0);

  const getEutons = () => {
    const eutons_obtained = eutons.filter(
      (euton) => studentEutons.some((se) => se.euton_id === euton.id)
      //euton.asset.length > 0
    );
    const walking_eutons = eutons_obtained.filter(
      (euton) => euton.biome.id === studentState.studentBiome?.id
    );

    setWalkingEutons(walking_eutons);
  };

  useFrame(() => {
    // ORBIT CONTROLS
    const vectorPosition = controlsRef.current.target;
    if (vectorPosition.x > 25) {
      vectorPosition.x = 25;
    } else if (vectorPosition.x < -25) {
      vectorPosition.x = -25;
    }

    if (vectorPosition.z > 40) {
      vectorPosition.z = 40;
    } else if (vectorPosition.z < -20) {
      vectorPosition.z = -20;
    }

    console.log('x', vectorPosition.x, 'z', vectorPosition.z);
  });

  const resetOstrichEgg = () => {
    setOstrichEgg(false);
    setOstrichEggCount(0);
  };

  useEffect(() => {
    if (ostrichEggCount == 4) {
      setOstrichEgg(true);
      setTimeout(() => resetOstrichEgg(), 10000);
    }
  }, [ostrichEggCount]);

  const ostrichEggHandler = (number: number) => {
    for (let i = 0; i < 4; i++) {
      if (i == number - 1 && ostrichEggCount == i) {
        setOstrichEggCount(ostrichEggCount + 1);
        break;
      } else {
        setOstrichEggCount(0);
      }
    }
  };

  const GrimmFBX = () => {
    const grimmRef = useRef<THREE.Group>(null!);
    let fbx = useFBX("HN_GrimmChild_Anim_final.LP_96frames_baked.fbx");
    const fbxMesh = fbx.children[0] as THREE.Mesh;
    const susTexture = useTexture("GrimmchildTexture.png");
    susTexture.colorSpace = THREE.SRGBColorSpace;
    susTexture.flipY = true;
    const susMaterial = new THREE.MeshPhongMaterial({
      map: susTexture,
      emissiveMap: susTexture,
      emissive: new THREE.Color(0x000000),
    });
    fbxMesh.material = susMaterial;

    const grimmActions = useAnimations(
      fbx.animations,
      grimmRef as MutableRefObject<THREE.Group>
    );

    useEffect(() => {
      grimmActions.actions[
        "Armature|Armature|Armature|ArmatureAction.001"
      ]?.play();
    }, [grimmActions]);

    useFrame((state, delta) => {
      if (ostrichEgg) {
        grimmRef.current.position.y += 1 * delta;
        grimmRef.current.rotation.y += 5 * delta;
      }
    });

    return (
      <group>
        <primitive
          ref={grimmRef}
          object={fbx}
          scale={0.01}
          position={[8.05, -1, 0.27]}
          rotation={[0, Math.PI, 0]}
        />
        {/* {mode && <PositionalAudio
                url="./FreeBird.m4a"
                distance={1}
                position={[0,0,0]} 
                loop
                autoplay
                {...props} // All THREE.PositionalAudio props are valid
                />} */}
      </group>
    );
  };

  const bakedAssetsTexture1 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-new2.png"
  );
  const bakedAssetsTexture2 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-specular.png"
  );
  var bakedAssetsTexture3 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-emission.png"
  );

  bakedAssetsTexture1.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture1.flipY = false;
  bakedAssetsTexture2.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture2.flipY = false;
  bakedAssetsTexture3.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture3.flipY = false;

  if (funkyMode) {
    bakedAssetsTexture3 = bakedAssetsTexture1;
  }

  const bakedAssetsMaterial = new THREE.MeshStandardMaterial({
    map: bakedAssetsTexture1,
    emissiveMap: bakedAssetsTexture3,
    emissive: new THREE.Color(0xff0000),
    //specularMap: bakedAssetsTexture2,
    emissiveIntensity: 1,
    //specular: 0x666666,
    fog: true,
  });

  return (
    <>
      {/* <fog
        attach="fog"
        args={
          studentState.co2 <= 210
            ? ["#ffffff", 1, 150]
            : studentState.co2 <= 600
            ? ["#a6a6a6", 1, 150]
            : studentState.co2 <= 1000
            ? ["#a6a6a6", 1, 150]
            : ["#949494", 1, 150]
        }
      />
      <color attach="background" args={[1, 1, 1]} /> */}

      <OrbitControls
        ref={controlsRef}
        target={theme?.id === "theme_id" ? [0, 0, 0] : [-5.7, 0, 13.7]}
        panSpeed={1}
        enableDamping={false}
        autoRotateSpeed={0}
        enableRotate={false}
        dampingFactor={0}
        minDistance={9}
        maxDistance={props.maxDist}
        rotateSpeed={0}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 4}
        maxAzimuthAngle={(3 * Math.PI) / 4}
        minAzimuthAngle={(3 * Math.PI) / 4}
        screenSpacePanning={false}
        mouseButtons-LEFT={THREE.MOUSE.PAN}
        mouseButtons-RIGHT={THREE.MOUSE.ROTATE}
        touches-ONE={THREE.TOUCH.PAN}
      />

      <Environment preset="city" environmentIntensity={0.4} />

      <pointLight
        castShadow
        position={[35, 55, 30]}
        intensity={props.IntMult * 3.5}
        decay={0}
        shadow-normalBias={0.04}
        color={0xfafafa}
        shadow-mapSize={[2048, 2048]}
      />
      <ambientLight color={0xe9e9e9} intensity={props.IntMult * 0.4} />

      {/* Does not change depending on location */}

      {ostrichEgg && <GrimmFBX />}

      {/* {walkingEutons.length > 0 && (
        <MultiVehicle
          walkingEutons={walkingEutons}
          renderShadow={() => props.renderShadow()}
        />
      )} */}

      <T1R3F
        powerUpgrades={powerUpgrades}
        studentPower={studentPower}
        ostrichEggHandler={ostrichEggHandler}
        renderShadow={() => props.renderShadow()}
        renderAnimation={() => props.renderAnimation()}
        studentId={props.studentId}
        openChallenges={props.openChallenges}
        houseMains={props.houseMains}
        toggleMap={props.toggleMap}
        openLab={props.openLab}
        openEnergyLab={props.openEnergyLab}
        openWorkshop={props.openWorkshop}
        openHome={props.openHome}
        tutorial={props.tutorial}
        type={props.type}
        bakedAssetsMaterial={bakedAssetsMaterial}
        bakedAssetsTexture1={bakedAssetsTexture1}
        bakedAssetsTexture2={bakedAssetsTexture2}
        bakedAssetsTexture3={bakedAssetsTexture3}
        funkyMode={funkyMode}
        setFunkyMode={() => setFunkyMode(true)}
      />

      <T2R3F
        renderShadow={() => props.renderShadow()}
        renderAnimation={() => props.renderAnimation()}
        bakedAssetsMaterial={bakedAssetsMaterial}
        biomeName={props.type}
        openBiosphere={props.openBiosphere}
        openShuttle={props.openShuttle}
        openExplorersHut={props.openExplorersHut}
        tutorial={props.tutorial}
        eutons={walkingEutons}
      />
    </>
  );
};
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Local_ALL.gltf"
);

export default LocalMap;
