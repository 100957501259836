import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks.ts";
import { useCursor, useGLTF, useTexture } from "@react-three/drei";
import { DreiGLTF } from "../../../../interfaces.ts";
import * as THREE from "three";
import {
  Selection,
  Select,
  EffectComposer,
  Outline,
} from "@react-three/postprocessing";

import Parks from "./T1Buildings/Parks.tsx";
import StaticClickableAssets from "./T1Buildings/StaticClickableAssets.tsx";
import PuestosFeria from "./T1Buildings/PuestosFeria.tsx";
import Caminos from "./T1Buildings/Caminos.tsx";
import MejoraCaminos from "./T1Buildings/MejoraCaminos.tsx";
import MejorasEnergia from "./T1Buildings/MejorasEnergia.tsx";
import MejorasCasas from "./T1Buildings/MejorasCasas.tsx";

//Importing variants of the Local Map
import ModelJungle from "./LocalVariants/Jungle/ModelJungle.tsx";
import StaticAssetsJungle from "./LocalVariants/Jungle/StaticAssetsJungle.tsx";
import MejoraVegetacionJungle from "./LocalVariants/Jungle/MejoraVegetacionJungle.tsx";
import StaticAssetsGrassland from "./LocalVariants/Grassland/StaticAssetsGrassland.tsx";
import ModelGrassland from "./LocalVariants/Grassland/ModelGrassland.tsx";
import MejoraVegetacionGrassland from "./LocalVariants/Grassland/MejoraVegetacionGrassland.tsx";
import ModelBeach from "./LocalVariants/Beach/ModelBeach.tsx";
import StaticAssetsBeach from "./LocalVariants/Beach/StaticAssetsBeach.tsx";
import MejoraVegetacionBeach from "./LocalVariants/Beach/MejoraVegetacionBeach.tsx";
import ModelSavannah from "./LocalVariants/Savannah/ModelSavannah.tsx";
import StaticAssetsSavannah from "./LocalVariants/Savannah/StaticAssetsSavannah.tsx";
import MejoraVegetacionSavannah from "./LocalVariants/Savannah/MejoraVegetacionSavannah.tsx";
import ModelDesert from "./LocalVariants/Desert/ModelDesert.tsx";
import StaticAssetsDesert from "./LocalVariants/Desert/StaticAssetsDesert.tsx";
import MejoraVegetacionDesert from "./LocalVariants/Desert/MejoraVegetacionDesert.tsx";
import ModelSnow from "./LocalVariants/Snow/ModelSnow.tsx";
import StaticAssetsSnow from "./LocalVariants/Snow/StaticAssetsSnow.tsx";
import MejoraVegetacionSnow from "./LocalVariants/Snow/MejoraVegetacionSnow.tsx";
import ModelSwamp from "./LocalVariants/Swamp/ModelSwamp.tsx";
import StaticAssetsSwamp from "./LocalVariants/Swamp/StaticAssetsSwamp.tsx";
import MejoraVegetacionSwamp from "./LocalVariants/Swamp/MejoraVegetacionSwamp.tsx";
import ModelMountain from "./LocalVariants/Mountain/ModelMountain.tsx";
import StaticAssetsMountain from "./LocalVariants/Mountain/StaticAssetsMountain.tsx";
import MejoraVegetacionMountain from "./LocalVariants/Mountain/MejoraVegetacionMountain.tsx";
import ModelVolcano from "./LocalVariants/Volcano/ModelVolcano.tsx";
import StaticAssetsVolcano from "./LocalVariants/Volcano/StaticAssetsVolcano.tsx";
import MejoraVegetacionVolcano from "./LocalVariants/Volcano/MejoraVegetacionVolcano.tsx";
import { updateInfoBar } from "../../../../reducers/studentSlice.ts";
import { PowerUpgradeType } from "../../../../_newapios/gamecontent/power_upgrades.ts";
import { StudentPowerType } from "../../../../_newapios/progress/student_power.ts";
import ModelForest from "./LocalVariants/Forest/ModelForest.tsx";
import StaticAssetsForest from "./LocalVariants/Forest/StaticAssetsForest.tsx";
import MejoraVegetacionForest from "./LocalVariants/Forest/MejoraVegetacionForest.tsx";

const T1R3F = ({ ...props }) => {
  const studentState = useAppSelector((state) => state.student);

  const biomeName: string = props.type;

  const powerUpgrades: PowerUpgradeType[] = props.powerUpgrades;
  const studentPower: StudentPowerType[] = props.studentPower;
  const [hovered, hover] = useState(false);

  const infoBar = studentState.infoBar;
  const dispatch = useAppDispatch();
  const theme = studentState.theme;
  const localAll = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Local_ALL.gltf"
  ) as DreiGLTF;

  const bakedAssetsTextureH2 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Mejoras_energ%C3%ADa_nuevas/color-atlas-new3+(1).png"
  );
  bakedAssetsTextureH2.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTextureH2.flipY = false;

  const bakedAssetsMaterialH2 = new THREE.MeshPhongMaterial({
    map: bakedAssetsTextureH2,
    emissiveMap: props.bakedAssetsTexture3,
    emissive: new THREE.Color(0xff0000),
    specularMap: props.bakedAssetsTexture2,
    emissiveIntensity: 8,
    specular: 0x666666,
    fog: true,
  });
  useCursor(
    hovered,
    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
  );

  const hideInfoBar = () => {
    var newInfoBar = { ...infoBar, display: "hidden" };
    dispatch(updateInfoBar(newInfoBar));
  };

  const hasReforestationUpgrade = () => {
    const reforestationUpgrade = powerUpgrades.find(
      (upg) => upg.name === "energy_upgrade_reforestation_name"
    );
    return (
      studentPower?.filter(
        (studPow) => studPow.power_upgrade_id === reforestationUpgrade?.id
      ).length > 0
    );
  };

  const hasMaterialUpgrade = () => {
    const materialUpgrade = powerUpgrades.find(
      (upg) => upg.name === "energy_upgrade_material_name"
    );
    return (
      studentPower?.filter(
        (studPow) => studPow.power_upgrade_id === materialUpgrade?.id
      ).length > 0
    );
  };

  return (
    <>
      <Parks
        GLTF={localAll}
        bakedAssetsMaterial={props.bakedAssetsMaterial}
        renderShadow={() => props.renderShadow()}
        ostrichEggHandler={(number: number) => props.ostrichEggHandler(number)}
      />
      <PuestosFeria
        GLTF={localAll}
        bakedAssetsTexture1={props.bakedAssetsTexture1}
        bakedAssetsTexture2={props.bakedAssetsTexture2}
        bakedAssetsTexture3={props.bakedAssetsTexture3}
        openChallenges={
          props.studentId == null ? props.openChallenges : () => { }
        }
        renderShadow={() => props.renderShadow()}
      />

      {/* User dependant stuff */}
      <MejorasEnergia
        GLTF={localAll}
        bakedAssetsMaterial={props.bakedAssetsMaterial}
        bakedAssetsMaterialH2={bakedAssetsMaterialH2}
        studentId={props.studentId}
        renderShadow={() => props.renderShadow()}
      />
      <MejorasCasas
        GLTF={localAll}
        bakedAssetsMaterial={props.bakedAssetsMaterial}
        studentId={props.studentId}
        funkyMode={props.funkyMode}
        houseMains={(position: number) => props.houseMains(position)}
        renderShadow={() => props.renderShadow()}
      />

      {hasMaterialUpgrade() && (
        <MejoraCaminos
          funkyMode={props.funkyMode}
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!hasMaterialUpgrade() && (
        <Caminos
          funkyMode={props.funkyMode}
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      <Selection>
        {/* {theme?.id === "theme_id" && (
          <EffectComposer multisampling={8} autoClear={false}>
            <Outline
              blur
              visibleEdgeColor={0xffffff}
              edgeStrength={100}
              width={hovered ? 1000 : 1500}
            />
          </EffectComposer>
        )} */}
        <Select enabled={theme?.id === "theme_id"}>
          <StaticClickableAssets
            toggleMap={() => {
              props.toggleMap();
              hideInfoBar();
            }}
            openLab={props.studentId == null ? props.openLab : () => { }}
            openEnergyLab={
              props.studentId == null ? props.openEnergyLab : () => { }
            }
            openWorkshop={
              props.studentId == null ? props.openWorkshop : () => { }
            }
            openHome={props.studentId == null ? props.openHome : () => { }}
            openChallenges={
              props.studentId == null ? props.openChallenges : () => { }
            }
            onPointerOver={() => {
              if (theme?.id === "theme_id") {
                hover(true);
              }
            }}
            onPointerOut={() => hover(false)}
            GLTF={localAll}
            bakedAssetsMaterial={props.bakedAssetsMaterial}
            renderShadow={() => props.renderShadow()}
            renderAnimation={() => props.renderAnimation()}
            tutorial={props.tutorial}
          />
        </Select>
      </Selection>

      {/* Changes depending on location */}

      {/* Grassland */}

      {biomeName == "grassland" && (
        <ModelGrassland
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "grassland" && (
        <StaticAssetsGrassland
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "grassland" && hasReforestationUpgrade() && (
        <MejoraVegetacionGrassland
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Jungle */}

      {biomeName == "jungle" && (
        <ModelJungle
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "jungle" && (
        <StaticAssetsJungle
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "jungle" && hasReforestationUpgrade() && (
        <MejoraVegetacionJungle
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Forest */}

      {biomeName == "forest" && (
        <ModelForest
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "forest" && (
        <StaticAssetsForest
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "forest" && hasReforestationUpgrade() && (
        <MejoraVegetacionForest
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Beach */}

      {biomeName == "beach" && (
        <ModelBeach
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "beach" && (
        <StaticAssetsBeach
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "beach" && hasReforestationUpgrade() && (
        <MejoraVegetacionBeach
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Savannah */}

      {biomeName == "savannah" && (
        <ModelSavannah
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "savannah" && (
        <StaticAssetsSavannah
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "savannah" && hasReforestationUpgrade() && (
        <MejoraVegetacionSavannah
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Desert */}

      {biomeName == "desert" && (
        <ModelDesert
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "desert" && (
        <StaticAssetsDesert
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "desert" && hasReforestationUpgrade() && (
        <MejoraVegetacionDesert
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Snow */}

      {biomeName == "snow" && (
        <ModelSnow
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "snow" && (
        <StaticAssetsSnow
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "snow" && hasReforestationUpgrade() && (
        <MejoraVegetacionSnow
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Swamp */}

      {biomeName == "swamp" && (
        <ModelSwamp
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "swamp" && (
        <StaticAssetsSwamp
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "swamp" && hasReforestationUpgrade() && (
        <MejoraVegetacionSwamp
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Mountain */}

      {biomeName == "mountain" && (
        <ModelMountain
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "mountain" && (
        <StaticAssetsMountain
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "mountain" && hasReforestationUpgrade() && (
        <MejoraVegetacionMountain
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Volcano */}

      {biomeName == "volcano" && (
        <ModelVolcano
          funkyMode={props.funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "volcano" && (
        <StaticAssetsVolcano
          GLTF={localAll}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          setFunkyMode={props.setFunkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {biomeName == "volcano" && hasReforestationUpgrade() && (
        <MejoraVegetacionVolcano
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
    </>
  );
};

export default T1R3F;
