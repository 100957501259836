import "./ProfessorEvaluationStudentList.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import {
  setEvaluationMenu,
  selectedStudent,
  updateIsStudentFree,
} from "../../../../reducers/teacherSlice";
import { StudentType } from "../../../../_newapios/user/student";
import { m, LazyMotion, motion } from "framer-motion";
import {
  GetStudentCO2API,
  GetStudentPopulationAPI,
  GetStudentWattsAPI,
  GetStudentPointsAPI,
  GetStudentBiodiversityAPI,
  GetStudentActiveItemsAPI,
  GetStudentDestructionAPI,
} from "../../../../_newapios/progress/student_game_metrics.ts";
import { useEffect, useState } from "react";
import _ from "lodash";
import pointsIcon from "../../../../assets/student/points_icon.svg";
import co2Icon from "../../../../assets/student/co2_icon.svg";
import kilowattsIcon from "../../../../assets/student/kilowatts_icon.svg";
import populationIcon from "../../../../assets/student/population_icon.svg";
import biodiversityIcon from "../../../../assets/student/T2/biodiversity.svg";
import ecosystemsDestructionIcon from "../../../../assets/student/T2/ecosystems_destruction.svg";
import geneticMaterialIcon from "../../../../assets/student/T2/genetic_material.svg";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import ActivitiesIcon from "../../../../assets/professor/activities_icon.tsx";
import islandIcon from "../../../../assets/professor/island_icon.svg";
import { GetStudentCoursePointsAPI } from "../../../../_newapios/progress/student_course_points.ts";
import { GetStudentBundlesAPI } from "../../../../_newapios/content/bundle.ts";

const ProfessorEvaluationMenu = ({ ...props }) => {
  const { t } = useTranslation(["common", "evaluation"]);

  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const students = mainState.students;
  const teacherState = useAppSelector((state) => state.teacher);
  const userLicense = teacherState.userLicense;
  const selectedClass = teacherState.selectedClass;
  const courses = mainState.courses;

  const views = ["activities", "village"];

  const [evaluationView, setEvaluationView] = useState<string>(views[0]);

  const [sorting, setSorting] = useState<string>("name");
  const [sortedStudents, setSortedStudents] = useState<StudentType[]>(students);

  const [studentsT1Metrics, setStudentsT1Metrics] = useState<
    {
      population: number;
      power: number;
      co2: number;
      points: number;
    }[]
  >([]);

  const [studentsT2Metrics, setStudentsT2Metrics] = useState<
    {
      biodiversity: number;
      items: number;
      destruction: number;
      points: number;
    }[]
  >([]);

  const [studentsCoursePoints, setStudentsCoursePoints] = useState<
    { [key: string]: number }[]
  >([]);

  const [studentsBundles, setStudentsBundles] = useState<
    {
      course_name: string;
      bundle_length: number;
    }[][]
  >([]);
  const [studentCourses, setStudentCourses] = useState<string[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);

  const [maxStudentId, setMaxStudentId] = useState<string>("");

  useEffect(() => {
    if (evaluationView === "activities") {
      loadStudentsCoursePoints();
    }
    if (evaluationView === "village") {
      if (selectedClass?.theme_id === "theme_id") {
        loadStudentsT1Metrics();
      } else {
        loadStudentsT2Metrics();
      }
    }
  }, [sortedStudents, evaluationView]);

  useEffect(() => {
    sortStudents();
  }, [
    students,
    sorting,
    selectedCourse,
    studentsCoursePoints,
    evaluationView,
    studentsT1Metrics,
    studentsT2Metrics,
  ]);

  const activitiesViewSorting = (student: StudentType) => {
    if (!selectedCourse) {
      return -1;
    }
    let studentPoints =
      -studentsCoursePoints[students.indexOf(student)][selectedCourse];
    return studentPoints;
  };

  const sortStudents = () => {
    let sorted_students = _.sortBy(students, [
      (student) => {
        switch (evaluationView) {
          case "activities":
            switch (sorting) {
              case "name":
                return student.username.toLowerCase();
              case "course":
                return activitiesViewSorting(student);
            }
            break;
          case "village":
            switch (sorting) {
              case "name":
                return student.username;
              case "points":
                return (
                  -studentsT1Metrics[students.indexOf(student)].points ||
                  -studentsT2Metrics[students.indexOf(student)].points
                );
              case "population":
                return -studentsT1Metrics[students.indexOf(student)].population;
              case "power":
                return -studentsT1Metrics[students.indexOf(student)].power;
              case "co2":
                return -studentsT1Metrics[students.indexOf(student)].co2;
              case "biodiversity":
                return -studentsT2Metrics[students.indexOf(student)].points;
              case "items":
                return -studentsT2Metrics[students.indexOf(student)].points;
              case "destruction":
                return -studentsT2Metrics[students.indexOf(student)].points;
            }
            break;
          default:
            return student.username;
        }
      },
    ]);
    setSortedStudents(sorted_students);
  };

  useEffect(() => {
    calcCourses();
  }, [studentsBundles]);

  if (students === null) {
    return;
  }

  const loadStudentsCoursePoints = async () => {
    let maxStudent: {
      student_id: string;
      general_value: number;
    } = {
      student_id: "",
      general_value: 0,
    };
    let temp_students_bundles: {
      course_name: string;
      bundle_length: number;
    }[][] = [];
    const temp_students_course_points = await Promise.all(
      sortedStudents?.map(async (student) => {
        const temp_student_course_points = await GetStudentCoursePointsAPI(
          student.id
        );
        const temp_student_bundles = await GetStudentBundlesAPI(student.id);
        const filtered_courses = courses.filter((course) =>
          temp_student_bundles.some((bundle) => {
            if (bundle.scenario?.course_id === course.id) {
              return true;
            }
          })
        );

        const treated_bundles = filtered_courses.map((course) => {
          let course_bundles = {
            course_name: course.acronym,
            bundle_length: temp_student_bundles.filter(
              (bundle) => bundle.scenario?.course_id === course.id
            ).length,
          };
          return course_bundles;
        });

        temp_students_bundles.push(treated_bundles);

        const generalValue = Object.values(temp_student_course_points)
          .map((value) => value)
          .reduce((a, b) => a + b, 0);
        if (generalValue > maxStudent.general_value) {
          maxStudent = {
            student_id: student.id,
            general_value: generalValue,
          };
        }

        return temp_student_course_points;
      })
    );
    setStudentsCoursePoints(temp_students_course_points);
    setMaxStudentId(maxStudent.student_id);
    setStudentsBundles(temp_students_bundles);
  };
  console.log(studentsCoursePoints, studentCourses);

  const calcCourses = () => {
    let courses: string[] = [];
    // studentsCoursePoints.forEach((studentCoursePoints) => {
    //   Object.keys(studentCoursePoints).forEach((course) => {
    //     if (!courses.includes(course)) {
    //       courses.push(course);
    //     }
    //   });
    // });
    studentsBundles.forEach((studentBundles) => {
      studentBundles.forEach((bundle) => {
        if (!courses.includes(bundle.course_name)) {
          courses.push(bundle.course_name);
        }
      });
    });
    setStudentCourses(courses);
  };

  const loadStudentsT1Metrics = async () => {
    let maxStudent: {
      student_id: string;
      general_value: number;
    } = {
      student_id: "",
      general_value: 0,
    };
    const temp_students_metrics = await Promise.all(
      sortedStudents?.map(async (student) => {
        const temp_student_metrics = await calcStudentMetrics(student);
        const generalValue =
          temp_student_metrics.population +
          temp_student_metrics.power +
          temp_student_metrics.co2 +
          temp_student_metrics.points;
        if (generalValue > maxStudent.general_value) {
          maxStudent = {
            student_id: student.id,
            general_value: generalValue,
          };
        }

        return temp_student_metrics;
      })
    );
    setStudentsT1Metrics(temp_students_metrics);
    setMaxStudentId(maxStudent.student_id);
  };

  const loadStudentsT2Metrics = async () => {
    let maxStudent: {
      student_id: string;
      general_value: number;
    } = {
      student_id: "",
      general_value: 0,
    };
    const temp_students_metrics = await Promise.all(
      sortedStudents?.map(async (student) => {
        const temp_student_metrics = await calcStudentT2Metrics(student);
        const generalValue =
          temp_student_metrics.biodiversity +
          temp_student_metrics.items +
          temp_student_metrics.destruction +
          temp_student_metrics.points;
        if (generalValue > maxStudent.general_value) {
          maxStudent = {
            student_id: student.id,
            general_value: generalValue,
          };
        }

        return temp_student_metrics;
      })
    );
    setStudentsT2Metrics(temp_students_metrics);
    setMaxStudentId(maxStudent.student_id);
  };

  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const renderIcon = (view: string) => {
    switch (view) {
      case "activities":
        return <ActivitiesIcon size={17} color={"black"} />;
      case "village":
        return <img className="icon" src={islandIcon} alt="island_icon" />;
    }
  };

  const studentHandler = (student: StudentType) => {
    dispatch(setEvaluationMenu("generalInfo"));
    dispatch(selectedStudent(student));
    dispatch(updateIsStudentFree(student.id === maxStudentId)); //TODO - when we have the evaluation data for all students, we can update this
  };

  const evaluationViewHandler = (view: string) => {
    setEvaluationView(view);
  };

  const courseSortingHandler = (course: string) => {
    if (selectedCourse === course) {
      setSorting("name");
      setSelectedCourse(null);
      return;
    }
    setSorting("course");
    setSelectedCourse(course);
  };

  const villageSortingHandler = (sort: string) => {
    if (sorting === sort) {
      setSorting("name");
      return;
    }
    setSorting(sort);
  };

  const calcStudentMetrics = async (student: StudentType) => {
    const studentPopulation = await GetStudentPopulationAPI(student.id);
    const studentPower = await GetStudentWattsAPI(student.id);
    const studentCO2 = await GetStudentCO2API(student.id);
    const studentPoints = await GetStudentPointsAPI(student.id);

    const studentPopulationValue = studentPopulation
      ? Math.round(studentPopulation * 100) / 100
      : 0;
    const studentPowerValue = studentPower
      ? Math.round(studentPower * 100) / 100
      : 0;
    const studentCO2Value = studentCO2 ? Math.round(studentCO2 * 100) / 100 : 0;
    const studentPointsValue = studentPoints || 0;

    return {
      population: studentPopulationValue,
      power: studentPowerValue,
      co2: studentCO2Value,
      points: studentPointsValue,
    };
  };

  const calcStudentT2Metrics = async (student: StudentType) => {
    const studentBiodiversity = await GetStudentBiodiversityAPI(student.id);
    const studentItems = await GetStudentActiveItemsAPI(student.id);
    const studentDestruction = await GetStudentDestructionAPI(student.id);
    const studentPoints = await GetStudentPointsAPI(student.id);

    const studentBiodiversityValue = studentBiodiversity
      ? Math.round(studentBiodiversity * 100) / 100
      : 0;
    const studentItemsValue = studentItems
      ? Math.round(studentItems * 100) / 100
      : 0;
    const studentDestructionValue = studentDestruction
      ? Math.round(studentDestruction * 100) / 100
      : 0;
    const studentPointsValue = studentPoints || 0;

    return {
      biodiversity: studentBiodiversityValue,
      items: studentItemsValue,
      destruction: studentDestructionValue,
      points: studentPointsValue,
    };
  };

  const renderStudentTop = () => {
    return (
      <div className="professor_evaluation_list_top">
        {renderViewSelector()}
        {evaluationView === "activities" && renderStudentTopActivitiesGeneral()}
        {evaluationView === "village" &&
          selectedClass?.theme_id === "theme_id" &&
          renderStudentTopTheme1()}
        {evaluationView === "village" &&
          selectedClass?.theme_id === "theme_id_2" &&
          renderStudentTopTheme2()}
      </div>
    );
  };

  const renderViewSelector = () => {
    return (
      <div
        className="professor_evaluation_list_top_selector"
        style={{ gridTemplateColumns: "repeat(" + views.length + ", 50%)" }}
      >
        {views.map((view) => {
          return (
            <div
              className="professor_evaluation_list_top_selector_box"
              style={
                view === evaluationView
                  ? {
                      backgroundColor: "#E1EDFF",
                      border: "1px solid #5090F0",
                    }
                  : {
                      opacity: 0.5,
                      backgroundColor: "#D9D9D9",
                    }
              }
              onClick={() => evaluationViewHandler(view)}
            >
              {renderIcon(view)}
              <div className="text_black_super_small_bold">{t(view)}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderStudentTopActivitiesGeneral = () => {
    return (
      <div
        className="professor_evaluation_list_top_content"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(" + studentCourses.length + ", 15vw)",
        }}
      >
        {studentCourses.map((course) => {
          return (
            <div className=" professor_evaluation_list_top_content_container">
              <div
                className="text_black_small_bold"
                style={{
                  marginLeft: "3%",
                  color: selectedCourse === course ? "#5090F0" : "#000000",
                }}
              >
                {t(course + "_name", { ns: "evaluation" })}
              </div>
              <ChevronDownIcon
                w={"3vh"}
                style={{ marginLeft: "3%" }}
                color={selectedCourse === course ? "#5090F0" : "#000000"}
                onClick={() => courseSortingHandler(course)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderStudentTopTheme1 = () => {
    return (
      <div
        className="professor_evaluation_list_top_content"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 25%)",
        }}
      >
        <div className=" center_container">
          <img
            className="icon"
            src={pointsIcon}
            alt="icon"
            style={{ maxHeight: "2.5vh" }}
          />
          <div
            className="text_blue_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "points" ? "underline" : "none",
            }}
          >
            {t("points")}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "points" ? "#5090F0" : "#000000"}
            onClick={() => villageSortingHandler("points")}
          />
          <Tooltip
            hasArrow
            label={t("points_info_tooltip")}
            bg={"#7D7D7D"}
            placement="top"
            sx={{
              fontFamily: "Causten",
              fontSize: "calc(5px + 0.5vw)",
              borderRadius: "5px",
            }}
            aria-label="Points Tooltip"
          >
            <InfoOutlineIcon
              w={"3vh"}
              style={{ marginLeft: "5%" }}
              color={"#5090f0"}
            />
          </Tooltip>
        </div>
        <div className=" center_container">
          <img
            className="icon"
            src={populationIcon}
            alt="icon"
            style={{ maxHeight: "3vh" }}
          />
          <div
            className="text_green_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "population" ? "underline" : "none",
            }}
          >
            {t("population").charAt(0).toUpperCase() + t("population").slice(1)}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "population" ? "#6ee6a7" : "#000000"}
            onClick={() => villageSortingHandler("population")}
          />
          <Tooltip
            hasArrow
            label={t("population_info_tooltip")}
            bg={"#7D7D7D"}
            placement="top"
            sx={{
              fontFamily: "Causten",
              fontSize: "calc(5px + 0.5vw)",
              borderRadius: "5px",
            }}
            aria-label="Population Tooltip"
          >
            <InfoOutlineIcon
              w={"3vh"}
              style={{ marginLeft: "5%" }}
              color={"#6ee6a7"}
            />
          </Tooltip>
        </div>
        <div className=" center_container">
          <img
            className="icon"
            src={kilowattsIcon}
            alt="icon"
            style={{ maxHeight: "4vh" }}
          />
          <div
            className="text_yellow_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "kilowatts" ? "underline" : "none",
            }}
          >
            {t("kilowatts").charAt(0).toUpperCase() + t("kilowatts").slice(1)}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "power" ? "#fbc412" : "#000000"}
            onClick={() => villageSortingHandler("power")}
          />
          <Tooltip
            hasArrow
            label={t("kilowatts_info_tooltip")}
            bg={"#7D7D7D"}
            placement="top"
            sx={{
              fontFamily: "Causten",
              fontSize: "calc(5px + 0.5vw)",
              borderRadius: "5px",
            }}
            aria-label="Kw Tooltip"
          >
            <InfoOutlineIcon
              w={"3vh"}
              style={{ marginLeft: "5%" }}
              color={"#fbc412"}
            />
          </Tooltip>
        </div>
        <div className=" center_container">
          <img
            className="icon"
            src={co2Icon}
            alt="icon"
            style={{ maxHeight: "3.5vh" }}
          />
          <div
            className="text_grey_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "co2" ? "underline" : "none",
            }}
          >
            {t("t_co2")}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "co2" ? "#b9b9b9" : "#000000"}
            onClick={() => villageSortingHandler("co2")}
          />
          <Tooltip
            hasArrow
            label={t("co2_info_tooltip")}
            bg={"#7D7D7D"}
            placement="top"
            sx={{
              fontFamily: "Causten",
              fontSize: "calc(5px + 0.5vw)",
              borderRadius: "5px",
              maxWidth: "19vw",
            }}
            aria-label="Co2 Tooltip"
          >
            <InfoOutlineIcon
              w={"3vh"}
              style={{ marginLeft: "5%" }}
              color={"#b9b9b9"}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderStudentTopTheme2 = () => {
    return (
      <div
        className="professor_evaluation_list_top_content"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 25%)",
        }}
      >
        <div className=" center_container">
          <img
            className="icon"
            src={pointsIcon}
            alt="icon"
            style={{ maxHeight: "2.5vh" }}
          />
          <div
            className="text_blue_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "points" ? "underline" : "none",
            }}
          >
            {t("points")}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "points" ? "#5090F0" : "#000000"}
            onClick={() => villageSortingHandler("points")}
          />
        </div>
        <div className=" center_container">
          <img
            className="icon"
            src={biodiversityIcon}
            alt="icon"
            style={{ maxHeight: "3vh" }}
          />
          <div
            className="text_green_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "biodiversity" ? "underline" : "none",
            }}
          >
            {t("biodiversity").charAt(0).toUpperCase() +
              t("biodiversity").slice(1)}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "biodiversity" ? "#5090F0" : "#000000"}
            onClick={() => villageSortingHandler("biodiversity")}
          />
        </div>
        <div className=" center_container">
          <img
            className="icon"
            src={geneticMaterialIcon}
            alt="icon"
            style={{ maxHeight: "4vh" }}
          />
          <div
            className="text_yellow_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "items" ? "underline" : "none",
            }}
          >
            {t("items").charAt(0).toUpperCase() + t("items").slice(1)}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "items" ? "#5090F0" : "#000000"}
            onClick={() => villageSortingHandler("items")}
          />
        </div>
        <div className=" center_container">
          <img
            className="icon"
            src={ecosystemsDestructionIcon}
            alt="icon"
            style={{ maxHeight: "3.5vh" }}
          />
          <div
            className="text_grey_small_bold"
            style={{
              marginLeft: "3%",
              textDecoration: sorting === "destruction" ? "underline" : "none",
            }}
          >
            {t("destruction").charAt(0).toUpperCase() +
              t("destruction").slice(1)}
          </div>
          <ChevronDownIcon
            w={"3vh"}
            style={{ marginLeft: "3%" }}
            color={sorting === "destruction" ? "#5090F0" : "#000000"}
            onClick={() => villageSortingHandler("destruction")}
          />
        </div>
      </div>
    );
  };

  const renderStudent = (student: StudentType, studentIndex: number) => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_evaluation_list_body_student"
          whileHover={{ scale: 1.02 }}
          onClick={() => studentHandler(student)}
        >
          <div className="professor_evaluation_student_name_icon">
            <div className="professor_evaluation_student_icon">
              <div className="center_container">
                <div className="text_white_small_bold">
                  {student.username.charAt(0).toUpperCase()}
                </div>
              </div>
            </div>
            <div
              className="text_black_very_small_bold"
              style={{ marginLeft: "3%" }}
            >
              {student.username}
            </div>
          </div>
          <div className="professor_evaluation_list_body_student_content">
            {evaluationView === "activities" &&
              renderStudentContentActivities(student, studentIndex)}
            {evaluationView === "village" &&
              renderStudentContentVillage(student, studentIndex)}
          </div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderStudentContentActivities = (
    student: StudentType,
    studentIndex: number
  ) => {
    return (
      <div
        className="professor_evaluation_list_body_student_content_data"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(" + studentCourses.length + ", 15vw)",
          filter:
            userLicense?.license.name === "Free"
              ? student.id === maxStudentId
                ? "blur(0px)"
                : "blur(4px)"
              : "blur(0)",
        }}
      >
        {studentCourses.map((course) => {
          const bundleLength = studentsBundles[studentIndex].find(
            (bundle) => bundle.course_name === course
          )?.bundle_length;
          return (
            <div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="text_black_small_bold">
                {studentsCoursePoints[studentIndex][course] || 0}
                {" / "}
                {bundleLength ? bundleLength * 100 : 0}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderStudentContentVillage = (
    student: StudentType,
    studentIndex: number
  ) => {
    return (
      <div
        className="professor_evaluation_list_body_student_content_data"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 25%)",
          filter:
            userLicense?.license.name === "Free"
              ? student.id === maxStudentId
                ? "blur(0px)"
                : "blur(4px)"
              : "blur(0)",
        }}
      >
        <div className="center_container">
          <div className="text_black_small_bold">
            {selectedClass?.theme_id === "theme_id"
              ? studentsT1Metrics[studentIndex]?.points
              : studentsT2Metrics[studentIndex]?.points}
          </div>
        </div>

        <div className="center_container">
          <div className="text_black_small_bold">
            {selectedClass?.theme_id === "theme_id"
              ? studentsT1Metrics[studentIndex]?.population
              : studentsT2Metrics[studentIndex]?.biodiversity}
          </div>
        </div>
        <div className="center_container">
          <div className="text_black_small_bold">
            {selectedClass?.theme_id === "theme_id"
              ? studentsT1Metrics[studentIndex]?.power
              : studentsT2Metrics[studentIndex]?.items}
          </div>
        </div>
        <div className="center_container">
          <div className="text_black_small_bold">
            {selectedClass?.theme_id === "theme_id"
              ? studentsT1Metrics[studentIndex]?.co2
              : studentsT2Metrics[studentIndex]?.destruction}
          </div>
        </div>
      </div>
    );
  };

  const renderNoStudents = () => {
    return (
      <div className="professor_evaluation_no_students_container">
        <div className="center_container">
          <img
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/nostudents_evaluation.gif"
            }
            style={{ maxHeight: "45vh" }}
          />
        </div>
        <div className="professor_evaluation_no_students_text">
          <div className="text_black_small_bold">{t("no_students_text_1")}</div>
          <div className="text_black_very_small" style={{ display: "inline" }}>
            {t("no_students_text_2")}
          </div>
          <div
            className="left_container"
            style={{
              display: "inline",
              fontSize: "calc(5px + 0.75vw)",
              fontFamily: "Causten",
            }}
          >
            <div style={{ color: "#000000" }}>{t("no_students_text_3")}</div>
            <m.div
              style={{
                color: "#5090f0",
                textDecoration: "underline",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              whileHover={{ textShadow: "0px 0px 10px 0px #5090F0" }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=bvHiOfRvl-k",
                  "_blank"
                )
              }
            >
              {'"' + t("tutorial_how_students_access") + '"'}
            </m.div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="professor_evaluation_student_list">
      {renderStudentTop()}
      {sortedStudents?.length === 0 && renderNoStudents()}
      <div className="professor_evaluation_student_list_body">
        {sortedStudents?.length > 0 &&
          sortedStudents?.map((student, index) => {
            return renderStudent(student, index);
          })}
      </div>
    </div>
  );
};

export default ProfessorEvaluationMenu;
